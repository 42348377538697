import React, { useState, useEffect, useContext } from 'react'
import './advanced.css'
import { isMobile, isDesktop, isTablet } from 'react-device-detect'
import Header from '../../components/Header/Header'
import NSSwitcher from '../../components/NSButton/Types/Switcher'
import NSBoolean from '../../components/NSButton/Types/Boolean'
import NSTimer from '../../components/NSButton/Types/Timer'
import NSCounter from '../../components/NSButton/Types/Counter'
import NSIndicator from '../../components/NSButton/Types/Indicator'
import NSSimpleButton from '../../components/NSButton/Types/SimpleButton'
import { faStopwatch } from '@fortawesome/free-solid-svg-icons'
import DialogVentilaciones from '../../components/DialogVentilaciones/DialogVentilaciones'
import DialogDown from '../../components/DialogDown/DialogDown'
import DialogEvaluaRitmo from '../../components/DialogEvaluaRitmo/DialogEvaluaRitmo'

import DialogDownNotes from '../../components/DialogDownNotes/DialogDownNotes'
import DialogCausaParada from '../../components/DialogCausaParada/DialogCausaParada'
import DialogTecnicasFarmacos from '../../components/DialogTecnicasFarmacos/DialogTecnicasFarmacos'
import DialogFinish from '../../components/DialogFinish/DialogFinish'
import {
	iconAdrenalina,
	iconAvisaEquipo,
	iconCausaParada,
	iconCompresiones,
	iconDesfibrilacion,
	iconMonitor,
	iconNoResponde,
	iconNotas,
	iconRitmoEcg,
	iconTecnicas,
	iconVentilaciones,
	iconReanimacion,
	logoAvanzado

} from '../../helpers/ImagesCache'
import { ContextoReset, GlobalContext, TimelineContext } from '../../helpers/Context'
import axios from 'axios';
import { BASEURLAPI } from '../../helpers/Constants';
import { resetTimeline } from '../../helpers/helpers'
import DialogHabilidades from '../../components/DialogHabilidades/DialogHabilidades'
import DialogNoTecnicas from '../../components/DialogNoTecnicas/DialogNoTecnicas'
import DialogCalidadRcp from '../../components/DialogCalidadRcp/DialogCalidadRcp'
import DialogMedirAmbas from '../../components/DialogMedirAmbas/DialogMedirAmbas'
import Loader from '../../components/Loader/Loader'
const Advanced = () => {

	//Contexto
	const contextGlobal = useContext(GlobalContext);
	const ctxTimeline = useContext(TimelineContext);
	const ctxReset = useContext(ContextoReset);
	//Estados
	const [isStarted, setIsStarted] = useState(false)
	const [ForceStopCase, setForceStopCase] = useState(false)
	const [isTryingToStop, setTsTryingToStop] = useState(false)

	const [canGetReport, setCanGetReport] = useState(false)
	const [timerCounter, setTimerCounter] = useState(0)
	const [isPopupActive, setIsPopupActive] = useState(true)
	const [isPopupEcgActive, setIsPopupEcgActive] = useState(false)
	const [isPopupRitmoActive, setIsPopupRitmoActive] = useState(false) 
	const [isPopupCausaActive, setIsPopupCausaActive] = useState(false)
	const [isPopupTecnicasActive, setIsPopupTecnicasActive] = useState(false)
	const [isPopupNotasActive, setIsPopupNotasActive] = useState(false)
	const [isPopupHabilidadesActive, setIsPopupHabilidadesActive] = useState(true)
	const [isPopUpNoTecnicas, setIsPopUpNoTecnicas] = useState(false)
	const [isPopUpCalidadRcp, setIsPopUpCalidadRcp] = useState(false)
	const [isPopUpMedirAmbas, setIsPopUpMedirAmbas] = useState(false)
	const [compresions, setCompresions] = useState(false)
	const [ecg, setEcg] = useState(TimelineContext.ECG) 
	const [ritmo, setRitmo] = useState( TimelineContext.monitor || [] )
	const [causaH, setCausaH] = useState(TimelineContext.CausaH || [])
	const [causaT, setCausaT] = useState(TimelineContext.CausaT || [])
	const [notas, setNotas] = useState('')
	const [stopValue, setStopValue] = useState(null)
	const [userId, setUserId] = useState(2)
	/**
	 * Estado que almacena si hay algun recurso en proceso para bloquear la pantalla a interacciones
	 * @type {boolean}
	 * @default false - al iniciar esta oculto
	 */
	const [isLoading, setIsLoading] = useState(false)
	/**
	 * Estado que almacena si hay algun recurso en proceso para bloquear la pantalla a interacciones
	 * @type {string}
	 * @default 'Guardando informe...' - al iniciar tiene el texto 'Guardando informe...'
	 */
	const [loadingText, setLoadingText] = useState('Guardando informe...')
	//Funciones


	/**
	 * Verifica si el informe ya se ha guardado en la base de datos.
	 * @async
	 * @function reportExist
	 * @todo
	 * api que verifique la existencia de un reporte
	 *  
	 */
	const reportExist = async () => {

		const url = `${BASEURLAPI}/caseExist` //la url para la solicitud
		let result = false
		await axios.get(url)
			.then((response) => {
				if (response.status == 200) {
					if (response.data.result == true) {
						result = true
					}
				}
			})
			.catch((error) => {
				console.log('hubo un error', error)
			})

		return result
	}
	/**
 * Guarda el ingorme en la base de datos una vez finalizado el caso.
 * 
 * @function SaveReport
 * @todo
 * verificar si el informe ya existe
 */
	const SaveReport = async () => {
		const exist = await reportExist()
		if (!exist) {
			setLoadingText('Espere mientras el informe es guardado...')
			setIsLoading(true)
			const url = `${BASEURLAPI}/createCase`
			const formData = new FormData()
			formData.append('userId', userId)
			formData.append('name', `TESTCASE-${+new Date()} (Avanzado)`)
			formData.append('timeline', JSON.stringify(ctxTimeline))
			formData.append('duration', 0)
			axios.post(url, formData)
				.then((response) => {
					if (response.status == 200) {
						console.log(response.data)

					} else {

					}
					ctxReset.resetTimeline()
					setIsLoading(false)
				})
				.catch((error) => {
					console.log('hubo un error', error)
					setIsLoading(false)
				})
		}
	}



	const handlerForceStopCase = (value) => setForceStopCase(value)
	const handlerStart = () => setIsStarted(true)
	const handlerStop = () => {

		setIsStarted(false)
		if (isTryingToStop) {
			//SaveReport()
			console.log('Historial completo', ctxTimeline.history)
		}
	}
	const handlerStopDialog = () => {

		setTsTryingToStop(!isTryingToStop)
	}
	const handlerCloseDialog = () => setIsPopupActive(false)
	

	const handlerRitmoDialog = () => setIsPopupRitmoActive(!isPopupRitmoActive)
	const handlerEcgDialog = () => setIsPopupEcgActive(!isPopupEcgActive)
	const handlerCausaDialog = () => setIsPopupCausaActive(!isPopupCausaActive)
	const handlerTecnicasDialog = () => setIsPopupTecnicasActive(!isPopupTecnicasActive)
	const handlerNotasDialog = () => setIsPopupNotasActive(!isPopupNotasActive)
	const handlerDialogNoTecnicas = () => {
		setIsPopUpNoTecnicas(true)
	}

	const handlerDialogCalidadRcp = () => {
		setIsPopUpCalidadRcp(true)
	}

	const handlerDialogMedirAmbas = () => {
		setIsPopUpMedirAmbas(true)
	}

	const handlerDialogNoTecnicasStop = () => {
		setIsPopUpNoTecnicas(false)
		setIsPopupHabilidadesActive(false)
	}

	const handlerDialogCalidadRcpStop = () => {
		setIsPopUpCalidadRcp(false)
		setIsPopupHabilidadesActive(false)
	}

	const handlerMedirAmbasStop = () => {
		setIsPopUpMedirAmbas(false)
		setIsPopupHabilidadesActive(false)
	}

	const handlerCompresions = () => {
		setIsPopupActive(false)
		setCompresions(true)
	}


	const handleSaveAndCloseNotecnicas = () => {
		setIsPopUpNoTecnicas(false)
		SaveReport()
	}
	const handleSaveAndCloseCalidadRcp = () => {
		setIsPopUpCalidadRcp(false)
		SaveReport()
	}

	const handleVolverNoTecnicas = () => {
		setIsPopUpNoTecnicas(false)
	}

	const handleVolverCalidadRcp = () => {
		setIsPopUpCalidadRcp(false)
	}

	const updateMonitor = () => {

		setRitmo(ctxTimeline.monitor || [])
	}

	//UseEffects
	useEffect(() => { setEcg(ctxTimeline.ECG) }, [ctxTimeline.ECG])
	useEffect(() => { setNotas(ctxTimeline.Notas) }, [ctxTimeline.Notas])
	//useEffect(() => { setCausaH(ctxTimeline.CausaH) }, [ctxTimeline.CausaH]) 
	//useEffect(() => { setCausaT(ctxTimeline.causaT) }, [ctxTimeline.causaT]) 
	useEffect(() => { setCanGetReport(timerCounter > 0) }, [isStarted])
	useEffect(() => { ctxTimeline.history = [] }, [])
	useEffect(() => { console.log('setForceStopCase') }, [ForceStopCase])

	useEffect(() => {
		//resetTimeline(ctxTimeline) 
	}, [])
	useEffect(() => {
		console.log("el contexto fue reseteado: ", ctxTimeline)
	}, [ctxTimeline])


	useEffect(() => {
		console.log('Historial actualizado', ctxTimeline.history)

	}, [ctxTimeline.history])

	return (
		<>
		<Loader status={isLoading} text={loadingText} />
			<div style={styles.main} >
				<Header options={{}} minimal={true} logo={logoAvanzado} />
				<div className='flex gap-8 my-3 lg:my-2'>
					<NSTimer
						milliseconds={false}
						options={{ titleOn: 'Parar caso', titleOff: 'Iniciar caso' }}
						onStartTimer={(value) => {
							ctxTimeline.startCase = value?.startAt
							ctxTimeline.history.push({
								time: value?.startAt,
								title: 'Inicio de Caso',
								object: 'startCase'
							})
							handlerStart()
						}}
						onStopTimer={(value) => {
							handlerForceStopCase(true)
							ctxTimeline.startCase = value?.startAt
							ctxTimeline.stopCase = value?.stopAt
							ctxTimeline.history.push({
								time: value?.stopAt,
								title: 'Fin del Caso',
								object: 'stopCase'
							})
							handlerStop()
							handlerStopDialog()
						}}
						forceDisable={ForceStopCase}
						forceStop={ForceStopCase}
					/>
					<NSTimer
						milliseconds={false}
						options={
							{
								titleOn: 'RCE',
								titleOff: 'Inicio PCR'
							}}
						onStopTimer={(value) => {
							ctxTimeline.RCE.push(
								{
									start: value?.startAt,
									stop: value?.stopAt
								}
							)
							ctxTimeline.history.push({
								time: value?.stopAt,
								title: 'RCE',
								object: 'RCE'
							})
						}}
						onStartTimer={(value) => {
							ctxTimeline.PCR.push(
								{
									start: value?.startAt
								}
							)
							ctxTimeline.history.push({
								time: value?.startAt,
								title: 'Inicio PCR',
								object: 'PCR'
							})
						}}
						forceDisable={!isStarted}
						forceStop={ForceStopCase}
					/>
				</div>
				<div className='w-full ' style={styles.bodyContent} >
					<div className="p-2 w-full  h-full " style={styles.switcherSection}>
						<div className="w-10/12 flex justify-between  ">
							<NSSwitcher
								options={{
									title: 'Acceso vascular',
									subtitleActive: '',
									subtitle: '',
									initialState: false
								}}
								onClick={(value) => {
									ctxTimeline.vascularAccess = value
									ctxTimeline.history.push({
										time: +new Date(),
										title: 'Acceso vascular',
										object: 'vascularAccess'
									})
								}}
								forceDisable={!isStarted}
								forceStop={ForceStopCase}
							/>
							<NSSwitcher
								options={{
									title: 'IOT/Dispositivo',
									subtitleActive: '',
									subtitle: '',
									initialState: false
								}}
								onClick={(value) => {
									ctxTimeline.otDevice = value
									ctxTimeline.history.push({
										time: +new Date(),
										title: 'IOT/Dispositivo',
										object: 'otDevice'
									})
								}}
								forceDisable={!isStarted}
								forceStop={ForceStopCase}
							/>
						</div>
						<div className="h-full" style={styles.buttonsSeccion}  >
							<NSBoolean
								title='Noresponde / No respira'
								icon={iconNoResponde}
								initialState={false}
								onClick={(value) => {
									if (value) {
										ctxTimeline.notRespond = +new Date()
									}
									ctxTimeline.history.push({
										time: +new Date(),
										title: 'No responde / No respira',
										object: 'notRespond'
									})
								}}
								forceDisable={!isStarted}
								forceStop={ForceStopCase}
							/>
							<NSBoolean

								title='Avisa equipo de reanimacion'
								icon={iconAvisaEquipo}
								initialState={false}
								onClick={(value) => {
									if (value == true) {
										ctxTimeline.notifiesEmergencyTeam = +new Date()
									}
									ctxTimeline.history.push({
										time: +new Date(),
										title: 'Avisa equipo RCP',
										object: 'notifiesEmergencyTeam'
									})
								}}
								forceDisable={!isStarted}
								forceStop={ForceStopCase}
							/>
							<NSCounter
								options={{

									titleStart: 'Monitor',
									titleOff: 'Evalua ritmo',
									titleOn: 'Evalua ritmo',
									icon: iconMonitor,
									initialState: false,
									disableInitialTimer: true,
									type: 'restart',
									onlyCounter: true
								}}
								sum={0}
								onStopTimer={(value) => {
									
									handlerRitmoDialog()
									updateMonitor()
									
								}}

								onStartTimer={(value) => {
									updateMonitor()
									
								}}
								list={ritmo}
								forceDisable={!isStarted}
								forceStop={ForceStopCase}

							/>


							{
								compresions &&
								<NSCounter
									options={{
										titleStart: 'Inicia compresiones',
										titleOff: 'Inicia compresiones',
										titleOn: 'Detiene compresiones',
										icon: iconCompresiones,
										initialState: true,
										onlyCounter: true
									}}
									sum={1}
									onStopTimer={(value) => {
										ctxTimeline.compressions.push(
											{
												start: value?.startAt,
												stop: value?.stopAt
											}
										)
										ctxTimeline.history.push({
											time: value?.stopAt,
											title: 'Detiene Compresiones ' + ctxTimeline.compressions.length,
											object: 'compressions'
										})
									}}
									onStartTimer={(value) => {
										ctxTimeline.history.push({
											time: value?.startAt,
											title: 'Inicia Compresiones' + ctxTimeline.compressions.length,
											object: 'compressions'
										})
									}}
									list={ctxTimeline.compressions}
									forceDisable={!isStarted}
									forceStop={ForceStopCase}
								/>
							}
							{
								compresions &&
								<NSCounter
									options={{
										titleStart: 'Inicia ventilaciones',
										titleOff: 'Inicia ventilaciones',
										titleOn: 'Detiene ventilaciones',
										icon: iconVentilaciones,
										initialState: false,
										onlyCounter: true
									}}
									sum={1}
									onStopTimer={(value) => {
										ctxTimeline.vents.push(
											{
												start: value?.startAt,
												stop: value?.stopAt
											}
										)
										ctxTimeline.history.push({
											time: value?.stopAt,
											title: 'Detiene ventilaciones ' + ctxTimeline.vents.length,
											object: 'vents'
										})
									}}
									onStartTimer={(value) => {
										ctxTimeline.history.push({
											time: value?.startAt,
											title: 'inicia ventilaciones ' + ctxTimeline.vents.length,
											object: 'vents'
										})
									}}
									list={ctxTimeline.vents}
									forceDisable={!isStarted}
									forceStop={ForceStopCase}
								/>
							}
							{
								!compresions &&

								<NSCounter
									options={{
										titleStart: 'Inicia RCP',
										titleOff: 'Inicia RCP',
										titleOn: 'Detiene RCP',
										icon: iconReanimacion,
										initialState: false
									}}
									sum={1}
									onStopTimer={(value) => {
										ctxTimeline.RCP.push(
											{
												start: value?.startAt,
												stop: value?.stopAt
											}
										)
										ctxTimeline.history.push({
											time: value?.stopAt,
											title: 'Detiene RCP ' + ctxTimeline.RCP.length,
											object: 'RCP'
										})
									}}
									onStartTimer={(value) => {
										ctxTimeline.history.push({
											time: value?.startAt,
											title: 'inicia RCP ' + ctxTimeline.RCP.length,
											object: 'RCP'
										})
									}}
									list={ctxTimeline.RCP}
									forceDisable={!isStarted}
									forceStop={ForceStopCase}
								/>
							}
							<NSCounter
								options={{
									titleStart: 'Desfibrilacion',
									titleOff: 'Desfibrilacion',
									titleOn: 'Desfibrilacion',
									icon: iconDesfibrilacion,
									initialState: false,
									type: 'restart',
									onlyCounter: true
								}}
								sum={1}
								onStopTimer={(value) => {
									ctxTimeline.defibrillation.push(
										{
											start: value?.startAt,
											stop: value?.stopAt
										}
									)
									ctxTimeline.history.push({
										time: value?.stopAt,
										title: 'Desfibrilacion ' ,
										object: 'defibrillation',
										colorPrimario: '#F4D947',
									})
								}}
								onStartTimer={(value) => {
									ctxTimeline.history.push({
										time: value?.startAt,
										title: 'Desfibrilacion ',
										object: 'defibrillation',
										colorPrimario: '#F4D947',
										hideOnReport: true
									})
								}}
								list={ctxTimeline.defibrillation}
								forceDisable={!isStarted}
								forceStop={ForceStopCase}
							/>
							<NSCounter
								options={{
									titleStart: 'Adrenalina',
									titleOff: 'Adrenalina',
									titleOn: 'Adrenalina',
									icon: iconAdrenalina,
									initialState: false,
									type: 'restart',
									onlyCounter: true
								}}
								sum={1}
								onStopTimer={(value) => {
									ctxTimeline.adrenalin.push(
										{
											start: value?.startAt,
											stop: value?.stopAt
										}
									)
									ctxTimeline.history.push({
										time: value?.stopAt,
										title: 'Adrenalina',
										object: 'adrenalin'
									})
								}}
								onStartTimer={(value) => {
									ctxTimeline.history.push({
										time: value?.startAt,
										title: 'Adrenalina',
										object: 'adrenalin'
									})
								}}
								list={ctxTimeline.adrenalin}
								forceDisable={!isStarted}
								forceStop={ForceStopCase}
							/>
							<NSCounter
								options={{
									titleStart: 'Amiodarona',
									titleOff: 'Amiodarona',
									titleOn: 'Amiodarona',
									icon: iconAdrenalina,
									initialState: false,
									type: 'restart',
									onlyCounter: true
								}}
								sum={1}
								onStopTimer={(value) => {
									ctxTimeline.amiodarone.push(
										{
											start: value?.startAt,
											stop: value?.stopAt
										}
									)
									ctxTimeline.history.push({
										time: value?.stopAt,
										title: 'Amiodarona',
										object: 'amiodarone'
									})
								}}
								onStartTimer={(value) => {
									ctxTimeline.history.push({
										time: value?.startAt,
										title: 'Amiodarona',
										object: 'amiodarone'
									})
								}}
								list={ctxTimeline.amiodarone}
								forceDisable={!isStarted}
								forceStop={ForceStopCase}
							/>
							<NSIndicator
								options={{
									title: 'Detecta causa parada',
									icon: iconCausaParada,
									values: [
										{ title: 'H', status: causaH.length > 0 },
										{ title: 'T', status: causaT.length > 0 }
									],
									initialState: true
								}}
								onClick={() => {
									handlerCausaDialog()
									ctxTimeline.history.push({
										time: +new Date(),
										title: 'Actualizo Causa Parada',
										object: 'CausaT'
									})
								}}
								forceDisable={!isStarted}
								forceStop={ForceStopCase}
							/>
							{!compresions && <NSIndicator options={{}} />}
							<NSIndicator
								options={{
									title: 'Ritmo ECG',
									icon: iconRitmoEcg,
									type: 'oneSelection',
									values: [
										{
											status: ecg != '' ? true : false,
											title: ecg != '' ? ecg : ''
										}
									],
									minimal: true,
									initialState: true
								}}

								onClick={() => {
									handlerEcgDialog()
									ctxTimeline.history.push({
										time: +new Date(),
										title: 'Actualizo ritmo ECG',
										object: 'ECG'
									})
								}}
								callbackCancel={() => {
									//handlerEcgDialog()
								}}
								forceDisable={!isStarted}
								forceStop={ForceStopCase}
							/>
							<NSIndicator
								options={{
									title: 'Técnicas y Fármacos',
									icon: iconTecnicas,
									values: [],
									initialState: true
								}}

								onClick={() => {
									handlerTecnicasDialog()
									ctxTimeline.history.push({
										time: +new Date(),
										title: 'actualizo tecnicas y farmacos',
										object: 'Tecnicas'
									})
								}}
								forceDisable={!isStarted}
								forceStop={ForceStopCase}
							/>
							<NSIndicator
								options={{
									title: 'Notas',
									icon: iconNotas,
									values: [
										{ status: notas == '' ? false : true },
									],
									minimal: true,
									initialState: true
								}}

								onClick={() => {
									handlerNotasDialog()
									
								}}
								forceDisable={!isStarted}
								forceStop={ForceStopCase}
							/>
						</div>
					</div>
				</div>

				<div className='w-full ' style={styles.footer} >
					<NSSimpleButton
						options={{ title: 'Informe' }}
						initialState={ForceStopCase}
						callBack={SaveReport}
					/>
				</div>
			</div>
			<DialogVentilaciones
				text={'Desea medir compresiones y ventilaciones?'}
				icon={faStopwatch}
				callbackConfirm={handlerCompresions}
				callbackCancel={handlerCloseDialog}
				status={isPopupActive}
			/>


			<DialogDown
				text={'RITMO ECG PACIENTE'}
				icon={iconRitmoEcg}
				callbackConfirm={(ritmo) => {
					setEcg(ritmo)
					handlerEcgDialog()
				}}
				callbackCancel={handlerEcgDialog}
				status={isPopupEcgActive}
			/>

				{
					isPopupRitmoActive && 
					<DialogEvaluaRitmo
						text={'Evaluar Ritmo'}
						icon={iconRitmoEcg}
						callbackConfirm={(value) => { 
							
							ctxTimeline.monitor.push(
								{
									start: value?.startAt,
									stop: value?.stopAt
								}
							)
							updateMonitor()
							ctxTimeline.history.push({
								time: value?.startAt,
								title: 'Evalua ritmo - ',
								object: 'monitor',
								value: value.ritmo,
								colorPrimario: '#000a',
								colorSecundario:'#2AAC9C'
							})
							handlerRitmoDialog()
						}}
						callbackCancel={()=>{
							setIsPopupRitmoActive(false)
							updateMonitor()
						}}
						status={true}
					/>	
				}

			<DialogCausaParada
				icon={iconCausaParada}
				callbackConfirm={(H, T) => {
					setCausaH(H)
					setCausaT(T)
					handlerCausaDialog()
				}}
				callbackCancel={handlerCausaDialog}
				status={isPopupCausaActive}
			/>
			<DialogTecnicasFarmacos
				title={'Técnicas y Fármacos'}
				icon={iconTecnicas}
				callbackConfirm={handlerTecnicasDialog}
				callbackCancel={handlerTecnicasDialog}
				status={isPopupTecnicasActive}
			/>
			{
				isPopupNotasActive &&
				<DialogDownNotes
					text={'Notas'}
					icon={iconNotas}
					callbackConfirm={(text)=> {
						ctxTimeline.history.push({
							time: +new Date(),
							title: 'Notas: ',
							object: 'Notas',
							value: text,
							colorPrimario: '#2AAC9C',
						})
						handlerNotasDialog()
					}}
					callbackCancel={handlerNotasDialog}
					status={isPopupNotasActive}
				/>
			}

			<DialogHabilidades
				text={'¿Desea medir las habilidades no técnicas y/o la calidad de las RCP?'}
				callbackConfirm={handlerStop}
				callbackCancel={() => setIsPopupHabilidadesActive(false)}
				status={ForceStopCase && isPopupHabilidadesActive}
				action01={() => handlerDialogNoTecnicas()}
				action02={() => handlerDialogCalidadRcp()}
				action03={() => handlerDialogMedirAmbas()}
				action04={() => {
					SaveReport()
					setIsPopupHabilidadesActive(false)
				}}
			/>
			<DialogNoTecnicas
				callbackConfirm={handlerDialogNoTecnicas}
				callbackCancel={() => handlerDialogNoTecnicasStop()}
				status={isPopUpNoTecnicas}
				volverNoTecnicas={() => handleVolverNoTecnicas()}
				actionNoTecnicas={() => handleSaveAndCloseNotecnicas()
					
				}
				saveAnd={"Guardar y generar informe"}

			/>

			<DialogCalidadRcp
				callbackConfirm={handlerDialogCalidadRcp}
				callbackCancel={() => handlerDialogCalidadRcpStop()}
				status={isPopUpCalidadRcp}
				volverCalidadRcp={() => handleVolverCalidadRcp()}
				actionCalidadRcp={() => handleSaveAndCloseCalidadRcp()}
				saveAnd={"Guardar y generar informe"}
			/>


			<DialogMedirAmbas
				callbackConfirm={handlerDialogMedirAmbas}
				callbackCancel={() => handlerMedirAmbasStop()}
				status={isPopUpMedirAmbas}
			/>
		</>
	)
}
export default Advanced


const styles = {
	main: {
		maxWidth: isTablet ? '100%' : isDesktop ? '1280px' : '700px',
		maxHeight: '100vh',
		position: 'fixed',
		left: 0,
		right: 0,
		top: 0,
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: 'auto',
		marginLeft: 'auto'

	},
	bodyContent: {
		display: 'flex',
		zIndex: 0,
		background: '#eaf7f5',
		overflowY: 'auto',
		height: '100%',
		minHeight: '70vh',
		maxHeight: '70vh'
	},
	switcherSection: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	},
	buttonsSeccion: {
		gap: isMobile ? '2%' : '1%',
		justifyContent: 'center',
		marginBottom: isMobile ? '200px' : '0px',
		display: 'flex',
		flexWrap: 'wrap',
		width: '100%',
		overflowY: 'auto'
	},
	footer: {
		zIndex: 1000,
		height: isMobile ? '10vh' : '10vh',
		background: '#2aac9cff',
		position: 'fixed',
		bottom: 0,
		left: 0,
		right: 0,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	}
}