/**
*  
*
* @author Juan Rico
* 
* @module Informes
*  
* @description Este módulo define el la pagina de Informes o `Reports`, que permite visualizar y gestionar informes.
* El componente se encarga de la obtención y eliminación de informes, así como de la 
* visualización de informes individuales con sus respectivos detalles.
* 
*/

/**
 * Importaciones necesarias para el la pagina de informes.
 */
import React, { useContext, useEffect, useState } from 'react' // Importa React y hooks.
import axios from 'axios'; // Librería para realizar solicitudes HTTP.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';  // Componente para usar íconos de FontAwesome. 
import { 
    faBolt,  
    faArrowDown, 
    faArrowLeft, 
    faArrowUp, 
    faClock, 
    faRefresh, 
    faStopwatch,  
    faTrashAlt 
} from '@fortawesome/free-solid-svg-icons';  // Íconos específicos para el componente.
import {  
    iconAdrenalina, 
    iconCompresiones, 
    iconDesfibrilacion, 
    iconInforme, 
    iconNotas 
} from '../../helpers/ImagesCache'; //Iconos especificos cargados en cache por el desarrolador
import './reports.css' // importacion de la hoja de estilos para esta pagina
import Header from '../../components/Header/Header' // Componente de la cabezera de la pagina.
import Timeline from '../../components/Timeline/Timeline'; // Componente para mostrar la linea de tiempo
import { TimelineContext } from '../../helpers/Context'; // Contexto que proporciona información sobre la línea de tiempo.
import { completeTimerFormat, formatTimer, timerFormat, TimerFormatResta } from '../../helpers/helpers'; // funcion para formatear el tiempo 
import { BASEURLAPI } from '../../helpers/Constants'; // URL base para las solicitudes API.
import NSListItem from '../../components/NSButton/Types/ListItem' // Componente para mostrar cada informe en la lista.

/**
 * Componente principal que maneja la visualización de informes.
 * 
 * @function Reports
 * @returns {JSX.Element} Un elemento que representa la interfaz de informes.
 */
 
const Reports = () => {   

    /**
     * Contexto de la linea de tiempo.
     * @type {Object}
     */
    const ctxTimeline = useContext( TimelineContext );

    /**
     * Estado que almacena el ID del informe actual seleccionado.
     * @type {number}
     */
    const [currentReport, setCurrentReport ] = useState(0)

    /**
     * Estado que almacena los detalles del informe seleccionado.
     * @type {Object}
     */
    const [selectedReport, setSelectedReport ] = useState({})

    /**
     * Estado que almacena el ID del usuario actual.
     * @type {number}
     */
    const [userId, setUserId ] = useState(2)

    /**
     * Estado que almacena la lista de informes obtenidos.
     * @type {Array<Object>}
     */
    const [reports, setReports] = useState([])

    /**
     * Estado que indica si los informes se están cargando.
     * @type {boolean}
     */
    const [isLoading, setIsLoading] = useState(true)

    /**
     * Estado que indica si se está mostrando el informe completo.
     * @type {boolean}
     */
    const [isFullReport, setIsFullReport] = useState(false)

    /**
     * Alterna la visualización del informe completo.
     * 
     * @function handlerFullReport
     */
    const handlerFullReport = () => {
        setIsFullReport(!isFullReport)
    }

    /**
     * Componente que renderiza la lista de informes.
     * 
     * @function ListOfReports
     * @returns {JSX.Element} Un elemento que muestra la lista de informes.
     */
    const ListOfReports = () => { 
        return (
            <div className='flex flex-col  w-full'>   
                {  reports.length > 0 ? 
                    reports.map(
                        (item)=> 
                            <NSListItem  
                                key={'reports-' + item.id}
                                options={item}  
                                onClick={ (value) => viewReport(value) } 
                                onDelete={deleteCase}
                             /> 
                    ) :
                    <span className='ml-10 text-xl text-[#f009] font-bold'>
                        { 
                            isLoading ? 
                            'Cargando datos':
                            'No hay Informes creados'
                        } 
                    </span>
                } 
            </div>
        )
    }

     /**
     * Componente que renderiza el informe actual seleccionado.
     * 
     * @function CurrentReport
     * @returns {JSX.Element} Un elemento que muestra el informe seleccionado.
     */
    const CurrentReport = () => {
        if(currentReport == 0) return (<></>)
        return (
            <>
            
            <div 
                style={{
                    height: isFullReport ? 'auto' : '30vh',
                    maxHeight: isFullReport ? 'auto' : '30vh'
                }} 
                className='flex flex-col  w-[100%] h-[auto] overflow-hidden'>   
                { 
                    selectedReport?.timeline?.history?.map(
                        (item, index)=> 
                             {
                                if( !item.hideOnReport ){
                                    return ( 
                                        <div key={'report-selected-'+index} className={` h-11/12 w-full cursor-pointer bg-white hover:bg-[#0008] hover:text-[#fff]  flex flex-col items-center `}>
                                            <div  className={`text-center flex  items-center gap-0 justify-between h-full w-full flex items-between`}> 
                                                <div className='text-sm text-left flex  p-1 justify-start items-center w-4/6 font-[500] gap-4'>
                                                    <span className='text-[#2aac9c] bg-[#0001] text-sm font-[600] p-1'>
                                                        {timerFormat( new Date(item.time - selectedReport?.timeline?.startCase))}
                                                         
                                                    </span> 
                                                    <div >
                                                        {
                                                            
                                                            <supr style={{color:item?.colorPrimario || "#000"}} className='text-xl'>
                                                                {  item.title }
                                                            </supr> 
                                                        }
                                                        {   item.value != undefined &&
                                                            <supr style={{color:item?.colorSecundario || "#000"}} className='text-sm font-[400]'>
                                                                {  item.value }
                                                            </supr> 
                                                        }
                                                    </div> 
                                                </div> 
                                            </div> 
                                        </div>
                                    )
                                }
                            }
                             
                    ) 
                } 
                
            </div>
            <div className=' cursor-pointer w-full h-10 p-6 flex items-center justify-center text-2xl bg-[#0002]' onClick={handlerFullReport}>
                    {
                        isFullReport? 
                        <div className='flex gap-10 items-center'>
                            <FontAwesomeIcon icon={faArrowUp} />
                            <span>Minimizar</span>
                            <FontAwesomeIcon icon={faArrowUp} />
                        </div>
                         :
                        <div className='flex gap-10 items-center'>
                            <FontAwesomeIcon icon={faArrowDown} /> 
                            <span>Mostrar todo</span>
                            <FontAwesomeIcon icon={faArrowDown} /> 
                        </div>
                    }
            </div>
            </>
        )
    }
    
    /**
     * Maneja la visualización de un informe específico.
     * 
     * @function viewReport
     * @param {number} reportId - ID del informe a visualizar.
     */
    const viewReport = (reportId) => {
        setCurrentReport(reportId)
    }

    /**
     * Regresa al estado inicial (sin informe seleccionado).
     * 
     * @function goToRoot
     */
    const goToRoot = () => {
        setCurrentReport(0)
    }

    /**
     * Obtiene el tiempo total de un objeto específico.
     * 
     * @function getTotalTimeOf
     * @param {string} object - El nombre del objeto del que se desea obtener el tiempo.
     * @returns {string} El tiempo total en formato de minutos y segundos.
     */
    const getTotalTimeOf = ( object ) => {
        let timeTemp = 0
        selectedReport?.timeline?.[object]?.map((item)=>{
            const time = item.stop - item.start 
            timeTemp += time
        })
        const totalTime = new Date(timeTemp)
        let minutes = totalTime.getMinutes()
        let seconds = totalTime.getSeconds() 
        seconds = seconds < 10 ? '0' + seconds : seconds 
        return  `${minutes}' ${seconds}"`
    } 

    /**
     * Obtiene el tiempo total de un objeto específico.
     * 
     * @function getTotalTime
     * @param {string} object - El nombre del objeto del que se desea obtener el tiempo total.
     * @returns {number} El tiempo total en milisegundos.
     */
    const getTotalTime = ( object ) => {
        let timeTemp = 0
        selectedReport?.timeline?.[object]?.map((item)=>{
            const time = item.stop - item.start 
            timeTemp += time
        }) 
        return  timeTemp
    } 

    /**
     * Suma el tiempo de un array de objetos.
     * 
     * @function getSumaTime
     * @param {Array<number>} objects - Array de tiempos a sumar.
     * @returns {string} El tiempo total en formato de minutos y segundos.
     */
    const getSumaTime = ( objects ) => {
        let timeTemp = 0
        objects.map((item)=>{ 
            timeTemp += item
        })
        const totalTime = new Date(timeTemp)
        let minutes = totalTime.getMinutes()
        let seconds = totalTime.getSeconds() 
        seconds = seconds < 10 ? '0' + seconds : seconds 
        return  `${minutes}' ${seconds}"`
    } 

    /**
     * Obtiene el tiempo medio de un objeto específico.
     * 
     * @function getMediaTimeOf
     * @param {string} object - El nombre del objeto del que se desea obtener el tiempo medio.
     * @returns {string} El tiempo medio en formato de minutos y segundos.
     */
    const getMediaTimeOf = ( object ) => {
        let timeTemp = 0
        let timesTemporal = []
        const total =  selectedReport?.timeline?.[`${object}`]?.length || 0
        if(total > 1){ 
            selectedReport?.timeline?.[`${object}`]?.sort((a,b)=>{
                const time = a.start - b.start 
                timesTemporal.push(time)
            })
            timesTemporal.forEach( item => timeTemp += item )
            let mediaTemp = timeTemp / timesTemporal.length
            const totalTime = new Date(mediaTemp)
            let minutes = totalTime.getMinutes()
            let seconds = totalTime.getSeconds() 
            seconds = seconds < 10 ? '0' + seconds : seconds 
            return  `${minutes}' ${seconds}"`
        } else {
            return  `00' 00"`
        }
    } 

    /**
     * Obtiene todos los informes del usuario.
     * 
     * @function getAllReports
     */
    const getAllReports = () => {
        
        setIsLoading(true)
        const url = `${BASEURLAPI}/getCases/${userId}`
        axios.get(url,null,{
            headers:{
                'Access-Control-Allow-Origin':'*'
            }
        })
        .then((response)=>{
            if(response.status == 200){ 
                setReports(response.data.data)
                console.log(response.data.message)
            }
            setIsLoading(false)
        })
        .catch((error)=>{
            console.log('hubo un error',error)
            setIsLoading(false)
        })
    }

    /**
     * Elimina un caso específico.
     * 
     * @function deleteCase
     * @param {number} id - ID del caso a eliminar.
     */
    const deleteCase = (id) => {
        const report = reports.find(a=> a.id == id )
        const sure = confirm(`Desea eliminar el reporte ${report?.name}`)
        if(sure){ 
            const url = `${BASEURLAPI}/deleteCase/${userId}/${id}`
            
            axios.get(url)
            .then((response)=>{
                if(response.status == 200){
                    console.log(response.data) 
                } 
                getAllReports()
            })
            .catch((error)=>{
                console.log('hubo un error',error) 
                getAllReports()
            })
        }
    }

    /**
     * Hook que se ejecuta al montar el componente y cada vez que userId es actualizado.
     */
    useEffect(()=>{
        getAllReports()
    },[userId])

    /**
     * Hook que se ejecuta al montar el componente y cada vez que currentReport es actualizado.
     */
    useEffect(()=>{
        const reporteActual = reports.find((item)=>item.id == currentReport)
        
        console.log('reporteActual',reporteActual)
        if(reporteActual?.id > 0){ 
            setSelectedReport( {
                ...reporteActual, 
                'timeline': JSON.parse(reporteActual?.timeline)
            })
        }

    },[currentReport])

    useEffect(()=>{
        console.log('selectedReport',selectedReport)
    },[selectedReport])

    return (
        <div className='overflow-x-hidden right-[auto] left-[auto] flex flex-col bg-white  h-[100vh]  max-w-[1280px] items-center justify-start w-[100vw]'>
            
            <Header options={{}} minimal className='' />
            
            <div className=' z-[0] p-4  flex flex-col  bg-[#fff] flex w-full items-center justify-start gap-5 '>
                
                <div className='flex w-full items-center justify-between gap-4 font-[600] text-[#0007]'>
                    <div className='flex w-full items-center gap-4'>
                        { currentReport > 0 && <FontAwesomeIcon className='cursor-pointer' icon={faArrowLeft} onClick={goToRoot} /> }
                        <img src={iconInforme} alt={iconInforme} />
                        <span>INFORMES</span> 
                    </div>
                    <div className='flex gap-4 cursor-pointer items-center justify-center h-full w-10 text-2xl text-[#2aac9c]' >
                        { currentReport > 0 && 
                            <FontAwesomeIcon 
                                className='hover:bg-red-100 cursor-pointer hover:text-red-500 flex text-black' 
                                icon={faTrashAlt} 
                                onClick={()=>deleteCase(selectedReport?.id)}/> 
                        }
                        <FontAwesomeIcon 
                            className='transform hover:rotate-[360deg] transition-ease  transition-[2s] ' 
                            icon={faRefresh } 
                            onClick={getAllReports} />
                    </div>
                </div>
                
                { currentReport == 0 && <ListOfReports /> } 
                { currentReport > 0 && 
                    ( 
                    <div className='w-full border-b-2 border-b-solid border-b-gay-500 pb-2'>
                        <span className='text-xl font-[500]'>
                            {selectedReport?.name} 
                        </span>
                    </div> 
                    ) 
                } 
                <div className='w-full '>
                    <CurrentReport /> 
                </div>
                {
                    currentReport > 0 &&
                    <div className='px-2 py-8 w-full mb-2 flex justify-between mt-2 relative bg-[#0001]'> 
                        <div className='w-[79%] h-auto'> 
                            <div style={{borderBottom:'solid 1px #0002'}} className='h-auto w-full flex justify-between items-center  overflow-hidden py-5'  > 
                                <div className='flex items-center px-2 w-2/12  h-auto'> 
                                    <div className='rounded flex justify-center items-center w-4 h-4 bg-black p-3 text-white'>
                                        <FontAwesomeIcon icon={faStopwatch} />
                                    </div>
                                </div>
                                <div className='w-10/12 h-auto'> 
                                    <Timeline 
                                        data={selectedReport?.timeline?.history} 
                                        titles={['Inicio caso','Fin Caso']} 
                                        filter={['startCase','stopCase']} 
                                        showTitle 
                                        />
                                </div>
                            </div>
                            <div className='w-full flex justify-between items-center  h-auto pt-5'> 
                                <div className='flex items-center px-2 w-2/12  h-auto'> 
                                    <span className='font-bold text-sm'>
                                        PCR
                                    </span>
                                </div>
                                <div className='w-10/12 h-auto'> 
                                    <Timeline 
                                        data={selectedReport?.timeline?.history} 
                                        titles={['PCR','RCE']} 
                                        filter={['PCR','RCE']} 
                                        type={'RCE'}
                                        ballColor={'#2aac9c99'}
                                        showTitle
                                        />
                                </div>
                            </div>
                            <div style={{borderBottom:'solid 1px #0002'}} className='w-full flex justify-between items-center  pb-5'> 
                                <div className='flex items-center px-2 w-2/12   h-auto'> 
                                    <span className='font-bold text-sm'>
                                        Descargas
                                    </span>
                                </div>
                                <div className='w-10/12'> 
                                    <Timeline 
                                        data={selectedReport?.timeline?.history} 
                                        titles={['Desfibrilacion','DEA']} 
                                        filter={['defibrillation','DEA']}  
                                        bg={'gray'} 
                                        ballColor={'yellow'}
                                        borderColor={'black'}
                                        borderWidth={'2px'}
                                        icon={faBolt}
                                        ballSize={'25px'} 
                                        />
                                </div>
                            </div>

                            {
                                selectedReport?.timeline?.RCP.length > 0 && 
                                <>
                                <div style={{borderBottom:'dashed 1px #0002 mb-10'}} className='w-full flex justify-between items-center pt-5 pb-1'> 
                                    <div className='flex items-center px-2 w-2/12 h-auto'> 
                                        <span className='font-bold text-sm'>
                                            RCP
                                        </span>
                                    </div>
                                    <div className='w-10/12'> 

                                        <Timeline 
                                            data={selectedReport?.timeline?.history} 
                                            titles={['RCP']} 
                                            filter={['RCP']}  
                                            bg={''} 
                                            ballColor={''}
                                            borderColor={''}
                                            borderWidth={''}
                                            icon={null}
                                            ballSize={''}
                                            type={'interval'}
                                            showTitle
                                        />
                                    </div>
                                </div>
                                </>
                            }
                            {
                                selectedReport?.timeline?.RCP.length == 0 && 
                                <>
                                <div style={{borderBottom:'solid 1px #0002  mb-1'}} className='w-full flex justify-between items-center pb-5 pt-2'> 
                                    <div className='flex items-center px-2 w-2/12   h-auto'> 
                                        <span className='font-bold text-xs '>
                                            Compresiones ventilaciones
                                        </span>
                                    </div>
                                    <div className='w-10/12'> 
                                        <Timeline 
                                            data={selectedReport?.timeline?.history} 
                                            titles={['compressions']} 
                                            filter={['compressions']}  
                                            bg={'transparent'} 
                                            ballColor={'#2aac9c'}
                                            borderColor={'transparent'}
                                            borderWidth={'2px'} 
                                            ballSize={'10px'} 
                                            type={'interval'}
                                            
                                        /> 
                                        <hr className=' border-[#2aac9c] border-1 w-5/6 border-solid left-0 ml-10'/>
                                    
                                        <Timeline 
                                            data={selectedReport?.timeline?.history} 
                                            titles={['vents']} 
                                            filter={['vents']}  
                                            bg={'transparent'} 
                                            ballColor={'#f55'}
                                            borderColor={'transparent'}
                                            borderWidth={'2px'} 
                                            ballSize={'10px'} 
                                            type={'interval'}
                                        /> 
                                    </div>
                                </div>
                                </>
                            }


                            <div style={{borderBottom:'solid 1px #0002'}} className='w-full flex justify-between items-center py-5'> 
                                <div className='flex items-center px-2 w-2/12   h-auto'> 
                                    <span className='font-bold text-sm'>
                                        Ritmo Paciente
                                    </span>
                                </div>
                                <div className='w-10/12'> 
                                    <Timeline 
                                        data={selectedReport?.timeline?.history} 
                                        
                                        filter={['adrenalin','amiodarone']} 
                                         
                                        />
                                </div>
                            </div> 
                            <div style={{borderBottom:'solid 1px #0002'}} className='w-full flex justify-between items-center py-5'> 
                                <div className='flex items-center px-2 w-2/12   h-auto'> 
                                    <span className='font-bold text-sm'>
                                       Evalua Ritmo
                                    </span>
                                </div>
                                <div className='w-10/12'> 
                                    <Timeline 
                                        data={selectedReport?.timeline?.history} 
                                        
                                        filter={['adrenalin','amiodarone']} 
                                         
                                        />
                                </div>
                            </div> 
                            <div className='w-full flex justify-between py-5'> 
                                <div className='flex items-center px-2 w-2/12   h-auto'> 
                                    <span className='font-bold text-sm'>
                                        Fraccion RCP
                                    </span>
                                </div>
                                <div className='w-10/12'> 
                                    <Timeline 
                                        data={selectedReport?.timeline?.history} 
                                          
                                        filter={['Vents','Compressions']} 
                                         
                                        />
                                </div>
                            </div> 
                        </div>
                        <div className='flex flex-col items-center gap-4 py-2 px-3 w-[19%] bg-white shadow-[#0002] rounded shadow-lg'>
                            <span className='text-sm font-bold'>Totales</span>
                            <div className='flex justify-between items-center w-full border-b border-b-solid border-b-2 pb-3'>
                                <div className='flex p-[10px] text-sm text-white rounded justify-center items-center w-4 h-4 bg-[#2aac9c]'>
                                    <FontAwesomeIcon icon={faStopwatch} />
                                </div>
                                <span className='font-[500] text-xs'>
                                    { TimerFormatResta(selectedReport?.timeline?.stopCase,selectedReport?.timeline?.startCase) }
                                </span> 
                            </div>
                            <div className='flex justify-between items-center w-full border-b border-b-solid border-b-2 pt-5 pb-10'>
                                <div className='flex p-[10px] text-sm text-white rounded justify-center items-center w-4 h-4 bg-[#2aac9c]'>
                                    <FontAwesomeIcon icon={faStopwatch} />
                                </div>
                                <span className='font-[500] text-xs'>
                                { TimerFormatResta(
                                    selectedReport?.timeline?.RCE?.[0]?.stop,
                                    selectedReport?.timeline?.RCE?.[0]?.start
                                ) }
                                </span> 
                            </div>
                            <div className='flex justify-between items-center w-full border-b border-b-dashed border-b-2 pt-3 pb-6'>
                                <div className='flex p-[10px] text-sm text-white rounded justify-center items-center w-4 h-4 bg-[#2aac9c]'>
                                    <FontAwesomeIcon icon={faStopwatch} />
                                </div>
                                <span className='font-[500] text-xs'>
                                    {selectedReport?.timeline?.RCP.length>0 && TimerFormatResta(
                                        selectedReport?.timeline?.RCP?.[selectedReport?.timeline?.RCP.length -1]?.stop ,
                                        selectedReport?.timeline?.RCP?.[0]?.start
                                    )}

                                    { selectedReport?.timeline?.RCP.length == 0 &&TimerFormatResta(
                                        selectedReport?.timeline?.vents?.[selectedReport?.timeline?.vents.length -1]?.stop ,
                                        selectedReport?.timeline?.compressions?.[0]?.start
                                    )}
                                </span> 
                            </div>
                            <div className='flex font-[500]  text-left text-xs gap-3 justify-between items-center w-full'>
                                <div className='w-4 h-2 bg-[#2aac9c]'>  </div>
                                <span className='w-9/12 text-ellipsis overflow-x-hidden'>  
                                    {'Compresiones'} 
                                </span> 
                            </div>
                            <div className='flex font-[500] text-left text-xs justify-between items-center w-full border-b-2 pb-4'>
                                <div className='w-4 h-2 bg-[#ff5555aa]'>  </div>
                                <span className='w-9/12 text-ellipsis overflow-x-hidden'>  {'Ventilaciones'} </span> 
                            </div>
                            <div className='flex justify-between items-center w-full border-b border-b-dashed border-b-2 pt-3 pb-7'>
                                 
                                 
                            </div>
                        </div>
                        
                    </div>
                } 
                {
                    currentReport > 0 &&
                    <div className='px-2 py-8 gap-2 w-full mb-10 flex flex-wrap justify-between mt-0 relative bg-[#0f01]'> 
                       
                        { selectedReport?.timeline?.type == 'advanced' &&
                            <div className='flex flex-col items-center gap-4 py-3 px-5 md:w-[32%] w-[48%] lg:w-[22%] bg-white shadow-[#0002] rounded shadow-lg'>
                            <div className='flex justify-between items-center w-full'>
                                <span className='text-xl font-bold'>
                                    Farmacos
                                </span>
                                <img className='h-8' src={iconAdrenalina} alt={'icon'}  />
                            </div>
                            <div className='flex justify-between items-center w-full'>
                                <span className='text-lg font-bold'>
                                    Adrenalina:
                                </span>
                                <span className='text-[#2aac9c] font-bold text-xl'>
                                { selectedReport?.timeline?.adrenalin?.length < 10 ? '0'+selectedReport?.timeline?.adrenalin?.length:selectedReport?.timeline?.adrenalin?.length}
                                </span>
                            </div>
                            <div className='flex justify-between items-center w-full border-b border-b-solid border-b-2 pb-3'>
                                <div className='flex  justify-between w-full justify-start items-center gap-2'> 
                                    <div className='flex p-[12px] text-sm text-white rounded justify-center items-center w-4 h-4 bg-[#2aac9c]'>
                                        <FontAwesomeIcon icon={faStopwatch} /> 
                                    </div>
                                    <span className='text-[11px]'>
                                        Tiempo medio de administracion
                                    </span>
                                </div>
                                <span className='font-[500] text-lg w-5/12'>
                                    { getMediaTimeOf('adrenalin') }
                                </span> 
                            </div>
                            <div className='flex justify-between items-center w-full'>
                                <span className='text-lg font-bold'>
                                    Amiodarona:
                                </span>
                                <span className='text-[#2aac9c] font-bold text-xl'>
                                { selectedReport?.timeline?.amiodarone?.length < 10 ? '0'+selectedReport?.timeline?.amiodarone?.length:selectedReport?.timeline?.amiodarone?.length}
                                </span>
                            </div>
                            <div className='flex justify-between items-center w-full border-b border-b-solid border-b-2 pb-3'>
                                <div className='flex justify-between w-full  justify-start items-center gap-2'> 
                                    <div className='flex p-[12px] text-sm text-white rounded justify-center items-center w-4 h-4 bg-[#2aac9c]'>
                                        <FontAwesomeIcon icon={faStopwatch} /> 
                                    </div>
                                    <span className='text-[11px]'>
                                        Tiempo medio de administracion
                                    </span>
                                </div>
                                <span className='font-[500] text-lg w-5/12'>
                                    { getMediaTimeOf('amiodarone') }
                                </span> 
                            </div>
                            <div className='flex justify-between items-center w-full'>
                                <span className='text-lg font-bold'>
                                    Naloxona:
                                </span>
                                <span className='text-[#2aac9c] font-bold text-xl'>
                                { selectedReport?.timeline?.naloxone?.length < 10 ? '0'+selectedReport?.timeline?.naloxone.length:selectedReport?.timeline?.naloxone?.length || '00'}
                                </span>
                            </div>
                            {<div className='flex justify-between items-center w-full'>
                                <span className='text-lg font-bold'>
                                    Diazepam:
                                </span>
                                <span className='text-[#2aac9c] font-bold text-xl'>
                                { selectedReport?.timeline?.diazepam?.length < 10 ? '0'+selectedReport?.timeline?.diazepam?.length:selectedReport?.timeline?.diazepam?.length || '00'}
                                </span>
                            </div>}
                            </div>
                        }
                        <div className='flex flex-col items-center gap-4 py-3 px-5 md:w-[32%] w-[48%] lg:w-[22%] bg-white shadow-[#0002] rounded shadow-lg'>
                            <div className='flex justify-between items-center w-full'>
                                <span className='text-xl font-bold'>
                                    Descargas
                                </span>
                                <img className='h-8' src={iconDesfibrilacion} alt={'icon'}  />
                            </div>
                            <div className='flex justify-between items-center w-full'>
                                <span className='text-lg font-bold'>
                                    Nº Descargas :
                                </span>
                                <span className='text-[#2aac9c] font-bold text-xl'>
                                    { 
                                        selectedReport?.timeline?.defibrillation?.length >= 1 &&
                                        <>
                                        {selectedReport?.timeline?.defibrillation?.length < 10 ?
                                         '0' + selectedReport?.timeline?.defibrillation?.length : 
                                         selectedReport?.timeline?.defibrillation?.length}
                                        </>
                                    }
                                    { 
                                        selectedReport?.timeline?.DEA?.length > 1 &&
                                        <>
                                        {selectedReport?.timeline?.DEA?.length < 10 ?
                                         '0' + selectedReport?.timeline?.DEA?.length : 
                                         selectedReport?.timeline?.DEA?.length}
                                        </>
                                    }
                                    {
                                        selectedReport?.timeline?.defibrillation?.length < 1  &&
                                        selectedReport?.timeline?.DEA?.length <= 1 &&
                                        '00'

                                    }
                                </span>
                            </div>
                            <div className='max-h-[320px] pr-3 overflow-y-auto overflow-x-hidden flex  flex-col gap-2 items-center w-full border-b border-b-solid border-b-2 pb-3'>
                                {
                                     selectedReport?.timeline?.defibrillation?.length > 0 &&
                                     <>
                                     {
                                        selectedReport?.timeline?.defibrillation?.map((item)=>{ 
                                            if(!item?.hideOnReport){

                                                const timeStartCase = selectedReport?.timeline?.startCase
                                                const totalTime = new Date( item.start - timeStartCase )
                                                let hours = totalTime.getHours()
                                                let minutes = totalTime.getMinutes()
                                                let seconds = totalTime.getSeconds()
                                                hours = hours < 10 ? '00'  : '00'
                                                minutes = minutes < 10 ? '0' + minutes : minutes
                                                seconds = seconds < 10 ? '0' + seconds : seconds
                                                return (<div className='flex justify-between items-center w-full'>
                                                    <div className='flex justify-between w-full items-center gap-2'> 
                                                        <div className='flex p-[12px] text-sm text-black rounded justify-center items-center w-4 h-4 bg-[#0003]'>
                                                            <FontAwesomeIcon icon={faClock} /> 
                                                        </div> 
                                                    </div>
                                                    <span className='font-[500] text-lg w-5/12'>
                                                        {`${hours}:${minutes}:${seconds} `}
                                                    </span> 
                                                </div>)
                                            }
                                    })
                                    }
                                    </>
                                } 
                                {
                                    selectedReport?.timeline?.DEA?.length > 0 &&
                                    <>
                                    {selectedReport?.timeline?.DEA?.map((item)=>{ 
                                         if(!item?.hideOnReport){

                                            const timeStartCase = selectedReport?.timeline?.startCase
                                            const totalTime = new Date( item.start - timeStartCase )
                                            let hours =   totalTime.getHours()
                                            let minutes = totalTime.getMinutes()
                                            let seconds = totalTime.getSeconds()
                                            hours = hours < 10 ? '00'  : '00'
                                            minutes = minutes < 10 ? '0' + minutes : minutes
                                            seconds = seconds < 10 ? '0' + seconds : seconds
                                            return (<div className='flex justify-between items-center w-full'>
                                                <div className='flex justify-between w-full items-center gap-2'> 
                                                    <div className='flex p-[12px] text-sm text-black rounded justify-center items-center w-4 h-4 bg-[#0003]'>
                                                        <FontAwesomeIcon icon={faClock} /> 
                                                    </div> 
                                                </div>
                                                <span className='font-[500] text-lg w-5/12'>
                                                    {`${hours}:${minutes}:${seconds}`}
                                                </span> 
                                            </div>)
                                         }
                                    })}
                                    </>
                                } 
                                
                            </div> 
                            <div className='flex justify-between items-center w-full border-b border-b-solid border-b-2 pb-3'>
                                <div className='flex w-full items-center gap-2 w-6/12'> 
                                    <div className='flex p-[12px] text-sm text-white rounded justify-center items-center w-4 h-4 bg-[#2aac9c]'>
                                        <FontAwesomeIcon icon={faStopwatch} /> 
                                    </div>
                                    <span className='text-[11px]'>
                                        Media entre descargas
                                    </span>
                                </div>
                                <span className='font-[600] text-lg w-6/12'> 
                                    { 
                                        selectedReport?.timeline?.defibrillation?.length > 0 && 
                                        getMediaTimeOf('defibrillation') 
                                    }
                                    { 
                                        selectedReport?.timeline?.DEA?.length > 0 && 
                                        getMediaTimeOf('DEA') 
                                    }
                                </span> 
                            </div> 
                             
                            
                            <div className='flex justify-between items-center w-full'>
                                <span className='text-lg font-bold'>
                                    Pausa perishock:
                                </span>
                                <span className='text-[#000] font-bold text-xl'>
                                    {'NO'}
                                </span>
                            </div>
                            
                        </div>
                        <div className='flex flex-col items-center gap-4 py-3 px-5 md:w-[32%] w-[48%] lg:w-[22%] bg-white shadow-[#0002] rounded shadow-lg'>
                            <div className='flex justify-between items-center w-full'>
                                <span className='text-xl font-bold'>
                                    Calidad RCP
                                </span>
                                <img className='h-8' src={iconCompresiones} alt={'icon'}  />
                            </div>
                            <div className='flex justify-between items-center w-full'>
                                <span className='text-md font-bold'>
                                    Tiempo RCP:
                                </span>
                                <span className='text-[#2aac9c] font-bold text-lg'>
                                    
                                    {   selectedReport?.timeline?.compressions?.length > 0 &&
                                        selectedReport?.timeline?.vents?.length > 0 &&
                                        getSumaTime(
                                            [getTotalTime('compressions'), getTotalTime('vents')]
                                        )
                                    }
                                    {   selectedReport?.timeline?.RCP?.length > 0 &&
                                        getSumaTime( [getTotalTime('RCP')] )
                                    }
                                </span>
                            </div>
                            <div className='flex bg-[#0001] justify-between items-center w-full p-1'>
                                <div className='flex w-full justify-start items-center gap-2'> 
                                    <div className='flex p-[12px] text-sm text-white rounded justify-center items-center w-4 h-4 bg-[#2aac9c]'>
                                        <FontAwesomeIcon icon={faStopwatch} /> 
                                    </div>
                                    <span className='text-[11px]'>
                                        Compresiones
                                    </span>
                                </div>
                                <span className='font-[500] text-md w-5/12'>
                                    { getTotalTimeOf('compressions') }
                                </span> 
                            </div>
                            <div className='flex bg-[#0001] justify-between items-center w-full  p-1'>
                                <div className='flex w-full justify-start items-center gap-2'> 
                                    <div className='flex p-[12px] text-sm text-white rounded justify-center items-center w-4 h-4 bg-[#2aac9c]'>
                                        <FontAwesomeIcon icon={faStopwatch} /> 
                                    </div>
                                    <span className='text-[11px]'>
                                        Ventilaciones
                                    </span>
                                </div>
                                <span className='font-[500] text-md w-5/12'>
                                    { getTotalTimeOf('vents') }
                                </span> 
                            </div>
                            <div className='flex bg-[#0001] justify-between items-center w-full p-1'>
                                <div className='flex w-full justify-start items-center gap-2'> 
                                    <div className='flex p-[12px] text-sm text-white rounded justify-center items-center w-4 h-4 bg-[#2aac9c]'>
                                        <FontAwesomeIcon icon={faStopwatch} /> 
                                    </div>
                                    <span className='text-[11px]'>
                                        Pausa RCP
                                    </span>
                                </div>
                                <span className='font-[500] text-lg w-5/12'>
                                    { getTotalTimeOf('RCP') }
                                </span> 
                            </div>
                             
                            <div className='min-h-24  flex flex-col gap-2 justify-between items-start w-full  border-t border-t-solid border-t-2 pt-3'>
                                <span className='text-lg font-bold flex  flex-col'>
                                    <b>Calidad: </b> 
                                    {
                                        selectedReport?.timeline?.rcpQuality?.filter((item)=>item.selectedOption != null)?.length == 0 &&
                                    
                                        <span className='text-lg font-[400]'>
                                            No se ha Medido 
                                        </span>
                                    }
                                </span>
                                {
                                    selectedReport?.timeline?.rcpQuality?.map((item)=>{
                                        if(item.selectedOption != null &&  item.selectedOption != undefined){ 
                                            const colorBG = item?.options[item.selectedOption]?.color
                                            const title = item?.options[item.selectedOption]?.title
                                            const desc = item?.options[item.selectedOption]?.desc  
                                            return (
                                                <div className='font-bold text-xs flex justify-between w-full border-b border-b-solid border-b-2 pb-2 items-center'>
                                                    
                                                    <span className='min-w-[40%] max-w-[40%] '>
                                                        {item.title}
                                                    </span>
                                                    <div 
                                                        style={{ background: `${colorBG}`, color:"black"}}
                                                        className="min-w-[55%] max-w-[55%]  h-auto rounded-[4px] flex flex-col justify-center items-start cursor-pointer gap-1 py-1 px-2" 
                                                        >
                                                        <h4 className="text-xst">{title}</h4>
                                                        <p className="text-xs font-[300] ">{desc}</p>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                                
                                
                            </div>
                        </div>
                        <div className='flex flex-col items-center gap-4 py-3 px-5 md:w-[32%] w-[48%] lg:w-[22%] bg-white shadow-[#0002] rounded shadow-lg'>
                            <div className='flex justify-between items-center w-full'>
                                <div className='flex flex-col  text-xl font-bold'> 
                                    <span> Habilidades</span>
                                    <span> no tecnicas </span>
                                </div>
                                <img className='h-8' src={iconNotas} alt={'icon'}  />
                            </div>
                            <div className='min-h-24  flex flex-col gap-2 justify-between items-start w-full pt-3'>
                                <span className='text-lg font-bold flex  flex-col'>
                                    
                                    {
                                        selectedReport?.timeline?.nonTechnical?.filter((item)=>item.selectedOption != null)?.length == 0 &&
                                    
                                        <span className='text-lg font-[400]'>
                                            No se ha Medido 
                                        </span>
                                    }
                                </span>
                                {
                                    selectedReport?.timeline?.nonTechnical?.map((item)=>{

                                        if(item.selectedOption != null && item.selectedOption != undefined){ 

                                            const colorBG = item?.options[item.selectedOption]?.color
                                            const title = item?.options[item.selectedOption]?.title
                                            const desc = item?.options[item.selectedOption]?.desc 
                                            return (
                                                <div className='font-bold text-xs flex justify-between w-full border-b border-b-solid border-b-2 pb-2 items-center'>
                                                    
                                                    <span className='min-w-[40%] max-w-[40%] '>
                                                        {item.title}
                                                    </span>
                                                    <div 
                                                        style={{ background: `${colorBG}`, color:"black"}}
                                                        className="min-w-[55%] max-w-[55%]  h-auto rounded-[4px] flex flex-col justify-center items-start cursor-pointer gap-1 py-1 px-2" 
                                                        >
                                                        <h4 className="text-xst">{title}</h4>
                                                        <p className="text-xs font-[300] ">{desc}</p>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                                
                                
                            </div>
                            
                        </div> 
                    </div>
                }
            </div>
            
        </div>
    )
}
export default Reports
