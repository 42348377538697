import { useState, useEffect,useContext } from 'react'
import { isMobile } from "react-device-detect";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { iconCalidadRcp } from '../../helpers/ImagesCache';
import CheckCalidadRcp from '../CheckCalidadRcp/CheckCalidadRcp';

import { TimelineContext } from "../../helpers/Context";


const DialogCalidadRcp = ({ callbackConfirm, callbackCancel, status, actionCalidadRcp, volverCalidadRcp, saveAnd }) => {

    const ctxTimeline = useContext(TimelineContext);
	const [isOpen, setIsOpen] = useState(status)
	const [canContinue, setCanContinue] = useState(false)
    const handlerClose = () => {
		if (callbackCancel) {
			callbackCancel()
		}
	}

	const handlerOnConfirm = () => {
		if (callbackConfirm) {
			callbackConfirm()
			console.log('try on click')
		}
	}

	useEffect(() => {
		setIsOpen(status)
	}, [status])

	const refreshContext = () => {

		const haveAnNull = ctxTimeline.rcpQuality.filter(item => item.selectedOption == null )
		if(haveAnNull.length == 0){
			setCanContinue(true)
		} else {
			setCanContinue(false)
		}

		console.log('ctxTimeline.rcpQuality',ctxTimeline.rcpQuality)
	}



    return (
        <>
            {isOpen &&
                <div className=" flex items-end justify-center absolute left-0 top-500 bg-[#0005] w-full h-[100%]" style={{ zIndex: 10001 }}>
                    <div className="flex flex-col w-full  bg-white"
                        style={{
                            width: isMobile ? '100%' : '100%',
                            height: isMobile ? '100%' : '90%',
                            padding: isMobile ? '10px' : '10px',
                        }}
                    >
                        <div className='flex flex-col items-end mr-4 mt-4'>
                            <button className="" onClick={handlerClose}>
                                <FontAwesomeIcon size='xl' icon={faClose} />
                            </button>
                        </div>
                        <div className='flex flex-row items-center ml-8'>
                            <img src={iconCalidadRcp} alt="" />
                            <h4 className='text-2xl font-bold text-[#000e] p-4 '>Calidad  RCP</h4>
                        </div>

                        <div className="flex p-8 gap-0 flex-col w-full h-[736px] overflow-y-auto overflow-x-hidden">
							{
								ctxTimeline.rcpQuality.map((item ) => {
									return (
										<CheckCalidadRcp
											titulo={ item.title } 
											desripcion={ item.description }
											object = { item.object }
											options = { item.options }
											selectedOption = { item.selectedOption }
											onChange={()=>{
												refreshContext()
											}}
											
										/>
									)
								})
							}
							 
						</div>


                        <div className="bg-[#DAD9DB] h-[1px]"></div>
                        <div className='h-32 w-full flex justify-center items-center gap-6 bg-white'>
                            <button className='w-[216px] h-[54px] bg-[#EDEDED] gap-1 rounded-lg p-4  font-bold'
                            onClick={volverCalidadRcp}
                            >Volver</button>
                            <input 
								type='button'
								style={{background:canContinue ? '#2AAC9C' : 'gray'}}
								className={`h-[54px]  gap-1 rounded-lg p-4 text-white font-bold cursor-pointer`}
								onClick={()=> {
									
										handlerOnConfirm()
									
								}}
								value={saveAnd}
								disabled={!canContinue}
							/>
                           
                        </div>
                    </div>
                </div>
            }
        </>);
}

export default DialogCalidadRcp;