import { faClose, faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState,useContext } from "react";
import { iconAdulto, iconNino } from "../../helpers/ImagesCache"; 
import NSSelector from "../NSSelector/NSSelector";
import { TimelineContext } from "../../helpers/Context";
import { isMobile } from "react-device-detect";
 
const DialogDownNotes = ({icon, text, callbackConfirm,callbackCancel,status}) => {
    const contextTimeline = useContext( TimelineContext );
    const [ isOpen, setIsOpen ] = useState(status)
    const [ notes, setNotes ] = useState( contextTimeline.Notas ) 
    
    const handlerClose = () => {
        setNotes(contextTimeline.Notas)
        if(callbackCancel){ 
            callbackCancel()
        }
    }
 
    const handlerChangeText = (e) => {
        setNotes(e.target.value)
    }

    const handlerSave = () => { 
        contextTimeline.Notas = notes 
        if(callbackConfirm){ 
            callbackConfirm(notes)
        }
        
    }

    useEffect(()=>{  
        
    },[])

    useEffect(()=>{ 
        setIsOpen(status) 
    },[status])

    
    

    
    return (<>
       {
            isOpen && 
            <div className="flex overflow-hidden  flex-col items-center justify-end absolute left-0 top-0 bg-[#0009] w-full h-[100vh]" style={{zIndex:10001}}> 
                
                    <div className='w-[100%] px-[40px] pt-4 pb-1 h-[60px] flex flex-col items-start bg-white overflow-hidden '>  
                        <FontAwesomeIcon className="absolute right-[30px]" onClick={handlerClose} icon={faClose} />
                        <div className="flex items-center gap-3">
                            <button className="" onClick={handlerClose}> 
                            {
                                icon &&
                                <img src={icon} alt="ritmo ecg" width={40} height={40}/>
                            }
                            </button>
                            <span className="text-2xl font-bold text-[#000e]">
                                { text ? text : 'Titulo' }
                            </span>

                        </div>
                    </div> 
                    <div className="w-[100%] px-4  pb-2 max-h-[70vh] flex flex-col items-start bg-white ">
                        
                        <div className="flex p-4  min-h-[40vh] flex-col w-full h-full items-start justify-start  overflow-auto">
                            
                            <textarea 
                                style={{resize:'none'}}
                                className="w-full"
                                autoComplete='off' 
                                autofocus 
                                placeholder="Escribe algo en las notas."
                                rows={22}
                                onChange={handlerChangeText}
                                value={notes}
                            >

                            </textarea>
                        </div>
                    </div>
                    <div 
                    style={{
                        height:isMobile?'80px':'80px',
                        marginBottom:isMobile?'60px':'0px',
                    }} 
                    className=' py-5 h-[auto] bg-[#fff] flex flex-col justify-center items-center w-full opacity-[100%] overflow-hidden'>  
                 
                        <button 
                             onClick={()=>handlerSave()}
                            className="bg-[#2aac9cff] py-2 px-4 text-xl text-white rounded font-bold">
                            Guardar y volver
                        </button> 
                    </div> 
            </div>
        }
    </>)
}

export default DialogDownNotes