
 
export const formatTimer = (value) => {
    switch(value){
        case 0: return '00';break;
        case 1: return '01';break;
        case 2: return '02';break;
        case 3: return '03';break;
        case 4: return '04';break;
        case 5: return '05';break;
        case 6: return '06';break;
        case 7: return '07';break;
        case 8: return '08';break;
        case 9: return '09';break;
        default: return value.toString();break;
    }
}

export const completeTimerFormat = (date) => {
    if(!date) return '00:00:00'
    let mm = date.getUTCMinutes()
    let ss = date.getSeconds()
    let cm = Math.round( date.getMilliseconds() / 10)

    mm = mm < 10 ? '0' + mm : mm
    ss = ss < 10 ? '0' + ss : ss
    cm = cm < 10 ? '0' + cm : cm 
    
    return `${mm}:${ss}:${cm}`

}

export const TimerFormatResta = (dateA, dateB) => {
    if(!dateA || !dateB) return '00:00:00'
    const dateTotal =  dateA - dateB
    const dateFinal = new Date(dateTotal)

    let mm = dateFinal.getUTCMinutes()
    let ss = dateFinal.getSeconds()
    

    mm = mm < 10 ? '0' + mm : mm
    ss = ss < 10 ? '0' + ss : ss
    //cm = cm < 10 ? '0' + cm : cm 
    
    return `00:${mm}:${ss}`

}

export const timerFormat = (date) => {
    if(!date) return '00:00'
    let mm = date.getUTCMinutes()
    let ss = date.getSeconds() 

    mm = mm < 10 ? '0' + mm : mm
    ss = ss < 10 ? '0' + ss : ss 

    return `${mm}:${ss}`

}
export const millisecondsFormat = (date) => {
    if(!date) return '00' 
    let cm = Math.round( date.getMilliseconds() / 10) 
    cm = cm < 10 ? '0' + cm : cm 
    return `${cm}` 
}
export const secondsFormat = (date) => {
    if(!date) return '00' 
    let ss = date.getSeconds()
    ss = ss < 10 ? '0' + ss : ss
    return `${ss}` 
}
export const minutesFormat = (date) => {
    if(!date) return '00' 
    let mm = date.getUTCMinutes()
    mm = mm < 10 ? '0' + mm : mm 
    return `${mm}` 
}

export const counterFormat = (value) => {
    if(!value) return '00' 
    const temporal = parseInt(value) < 10 ? '0' + value : value 
    return `${temporal}`

}


import { TimelineDefault } from "./Constants";
export const resetTimeline = (ctxTimeline) =>{ 
    const keys = Object.keys(ctxTimeline)
    keys.map(( key )=>{
        ctxTimeline[key] = TimelineDefault[key]
    }) 
}
