/* eslint-disable */
//lista de constantes
export const BASEURLAPI = 'https://simularcp.ninjastudio.dev/api/v1'
export const PRIMARY_COLOR = '#2aac9c'
export const SECONDARY_COLOR = '#eaf7f5'
export const THIRD_COLOR = '#f9fafb'





export const OptionsEvaluaRitmo = [
  'Asistolia',
  'AESP', 
  'Fibrilación ventricular',
  'Taquicardia ventricular sin pulso',
  'Otro'  


]
export const OptionsRitmoECG = [
  'Asistolia',
  'Actividad eléctrica sin pulso',
  'Fibrilación ventricular',
  'Taquicardia ventricular sin pulso',
  'Taquicardia ventricular con pulso',
  'Torsaide de pointes',
  'Bloqueo de 1er grado',
  'Bloqueo de 2º Mobitz I',
  'Bloqueo de 2º Mobitz II', 
  'Bloque de 3er grado',  
  'Taquicardia supraventricular',
  'Ritmo sinusal',  
]


const CausaParadaH = [
  'Hipoxia',
  'Hipovolemia',
  'Hipo-hiperpotasemia / Metabolicas',
  'Hipo-hipertemia', 
]

const CausaParadaT = [
  'Tombosis coronaria o pulmonar',
  'Neumotorax a tension',
  'Taponamiento cardiaco',
  'Toxicos', 
]

const Tecnicas = [
  'Canula de guedel',
  'Aspiracion de secreciones',
  'Transfusion de hemoderivados',
  'Calentamiento paciente', 
  'Enfriamiento paciente',
  'Drenaje pleural',
  'Trombolisis',
  'Angiografía coronaria'
]

const Farmacos = [
  'Bicarbonato',
  'Cloruro de calcio',
  'Gluconato de calcio',
  'Naloxona',
  'Flumazenilo',
  'Insulina rapida',
  'Atropina',
  'Sulfato de magnesio' 
]

export const OptionsCausaParada = [ CausaParadaH, CausaParadaT ]

export const OptionsTecnicasFarmacos = [Tecnicas, Farmacos]




export const RcpQuality = [
  {
    title: 'Profundidad',
    description: '',
    object:'profundidad',
    selectedOption: null,
    options: [{
      isSelected:false,
      title:'Déficit',
      desc:'No Realiza',
      color: "#FEEFF2",
			colorCheck: "#F9839A",
    },
    {
      isSelected:false,
      title:'Adecuado',
      desc:'Realiza parcial',
      color: "#FDF7DB",
			colorCheck: "#F4D947",
    },
    {
      isSelected:false,
      title:'Excelente',
      desc:'Realiza completo',
      color: "#EAF7F5",
			colorCheck: "#2AAC9C",
    }]
  },
  {
    title: 'Frecuencia',
    description: '',
    object:'frecuencia',
    selectedOption: null,
    options: [{
      isSelected:false,
      title:'Déficit',
      desc:'No Realiza',
      color: "#FEEFF2",
			colorCheck: "#F9839A",
    },
    {
      isSelected:false,
      title:'Adecuado',
      desc:'Realiza parcial',
      color: "#FDF7DB",
			colorCheck: "#F4D947",
    },
    {
      isSelected:false,
      title:'Excelente',
      desc:'Realiza completo',
      color: "#EAF7F5",
			colorCheck: "#2AAC9C",
    }]
  },
  {
    title: 'Liberacion toracica',
    description: '',
    object:'liberacion',
    selectedOption: null,
    options: [{
      isSelected:false,
      title:'Déficit',
      desc:'No Realiza',
      color: "#FEEFF2",
			colorCheck: "#F9839A",
    },
    {
      isSelected:false,
      title:'Adecuado',
      desc:'Realiza parcial',
      color: "#FDF7DB",
			colorCheck: "#F4D947",
    },
    {
      isSelected:false,
      title:'Excelente',
      desc:'Realiza completo',
      color: "#EAF7F5",
			colorCheck: "#2AAC9C",
    }]
  },
  {
    title: 'Posicion',
    description: '',
    object:'posicion',
    selectedOption: null,
    options: [{
      isSelected:false,
      title:'Déficit',
      desc:'No Realiza',
      color: "#FEEFF2",
			colorCheck: "#F9839A",
    },
    {
      isSelected:false,
      title:'Adecuado',
      desc:'Realiza parcial',
      color: "#FDF7DB",
			colorCheck: "#F4D947",
    },
    {
      isSelected:false,
      title:'Excelente',
      desc:'Realiza completo',
      color: "#EAF7F5",
			colorCheck: "#2AAC9C",
    }]
  },
  {
    title: '30:2',
    description: '',
    object:'302',
    selectedOption: null,
    options: [{
      isSelected:false,
      title:'Déficit',
      desc:'No Realiza',
      color: "#FEEFF2",
			colorCheck: "#F9839A",
    },
    {
      isSelected:false,
      title:'Adecuado',
      desc:'Realiza parcial',
      color: "#FDF7DB",
			colorCheck: "#F4D947",
    },
    {
      isSelected:false,
      title:'Excelente',
      desc:'Realiza completo',
      color: "#EAF7F5",
			colorCheck: "#2AAC9C",
    }]
  },
]


export const NonTechnical = [
  {
    title: 'Claridad de papeles',
    object:'papeles',
    description: 'Incluye  características a evaluar como liderazgo, roles, distribución trabajo y comprobaciones cruzadas',
    selectedOption: null,
    options: [{
      isSelected:false,
      title:'Déficit',
      desc:'No Realiza',
      color: "#FEEFF2",
			colorCheck: "#F9839A",
    },
    {
      isSelected:false,
      title:'Adecuado',
      desc:'Realiza parcial',
      color: "#FDF7DB",
			colorCheck: "#F4D947",
    },
    {
      isSelected:false,
      title:'Excelente',
      desc:'Realiza completo',
      color: "#EAF7F5",
			colorCheck: "#2AAC9C",
    }]
  },
  {
    title: 'Comunicacion',
    object:'comunicacion',
    description: 'Incluye  características a evaluar como liderazgo, roles, distribución trabajo y comprobaciones cruzadas',
    selectedOption: null,
    options: [{
      isSelected:false,
      title:'Déficit',
      desc:'No Realiza',
      color: "#FEEFF2",
			colorCheck: "#F9839A",
    },
    {
      isSelected:false,
      title:'Adecuado',
      desc:'Realiza parcial',
      color: "#FDF7DB",
			colorCheck: "#F4D947",
    },
    {
      isSelected:false,
      title:'Excelente',
      desc:'Realiza completo',
      color: "#EAF7F5",
			colorCheck: "#2AAC9C",
    }]
  },
  {
    title: 'Ayuda y gestion',
    object:'ayuda',
    description: 'Incluye  características a evaluar como liderazgo, roles, distribución trabajo y comprobaciones cruzadas',
    selectedOption: null,
    options: [{
      isSelected:false,
      title:'Déficit',
      desc:'No Realiza',
      color: "#FEEFF2",
			colorCheck: "#F9839A",
    },
    {
      isSelected:false,
      title:'Adecuado',
      desc:'Realiza parcial',
      color: "#FDF7DB",
			colorCheck: "#F4D947",
    },
    {
      isSelected:false,
      title:'Excelente',
      desc:'Realiza completo',
      color: "#EAF7F5",
			colorCheck: "#2AAC9C",
    }]
  },
  {
    title: 'Recursos',
    object:'recursos',
    description: 'Incluye  características a evaluar como liderazgo, roles, distribución trabajo y comprobaciones cruzadas',
    selectedOption: null,
    options: [{
      isSelected:false,
      title:'Déficit',
      desc:'No Realiza',
      color: "#FEEFF2",
			colorCheck: "#F9839A",
    },
    {
      isSelected:false,
      title:'Adecuado',
      desc:'Realiza parcial',
      color: "#FDF7DB",
			colorCheck: "#F4D947",
    },
    {
      isSelected:false,
      title:'Excelente',
      desc:'Realiza completo',
      color: "#EAF7F5",
			colorCheck: "#2AAC9C",
    }]
  },
  {
    title: 'Vision global',
    object:'vision',
    description: 'Incluye  características a evaluar como liderazgo, roles, distribución trabajo y comprobaciones cruzadas',
    selectedOption: null,
    options: [{
      isSelected:false,
      title:'Déficit',
      desc:'No Realiza',
      color: "#FEEFF2",
			colorCheck: "#F9839A",
    },
    {
      isSelected:false,
      title:'Adecuado',
      desc:'Realiza parcial',
      color: "#FDF7DB",
			colorCheck: "#F4D947",
    },
    {
      isSelected:false,
      title:'Excelente',
      desc:'Realiza completo',
      color: "#EAF7F5",
			colorCheck: "#2AAC9C",
    }]
  }
]






export const TimelineDefault = { 
    name:'simularcp-' + (+new Date()),
    type:"basic",
    date:+new Date(),
    history:[],
    startCase : null,  
    stopCase : null,
    PCR:[],
    RCE:[],
    isSafeScene:false,
    shoutedHelp:false,
    consistency:null,
    notRespond:null,
    openAirWay:[],
    checkVentilation:[],
    notifiesEmergencyTeam:null,
    lateralSafetyPosition:null,
    RCP:[],
    DEA:[],
    compressions:[],
    vents:[],
    defibrillation:[],
    adrenalin:[],
    amiodarone:[],
    naloxone:[],
    diazepam:[],
    vascularAccess:null,
    otDevice:null,
    monitor:[], 
    complete:false,
    conscious:true,
    toser:null,
    backBlows: [],
    compressionsAbs: [],
    compressionsThorax: [],
    basicVitalSupport:null,
    Ritmo:[],
    ECG:[],
    CausaT: [],
    CausaH: [],
    Tecnicas:[],
    Farmacos:[],
    Notas:[],
    deficit:[],
    calidadRcp:[],
    nonTechnical: NonTechnical,
    rcpQuality: RcpQuality
    
}



